import Vue from 'vue'
// import ElementUI from 'element-ui'
import {
  Button, Select, Form, FormItem, DatePicker, Input, Message, Icon, Option, Table,
  TableColumn,
  OptionGroup
} from 'element-ui'
Vue.use(Button)
Vue.use(Select)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(DatePicker)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Table)
Vue.use(TableColumn)

Vue.prototype.$message = Message
