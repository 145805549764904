import axios from '@/utils/request'

// 获取城市下拉
export const getCity = (params) => {
  return axios({
    url: '/get_city',
    method: 'get',
    params
  })
}

// 获取echarts温度
export const getWeather = (params) => {
  return axios({
    url: '/get_weather',
    method: 'get',
    params
  })
}

// 登录接口
export const login = (data) => {
  return axios({
    url: '/login',
    method: 'post',
    data
  })
}

// 导出接口
export const exportData = (params) => {
  return axios({
    url: '/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
