import { Message } from 'element-ui'
import axios from 'axios'
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (localStorage.getItem('token')) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  console.log(response, 'response')
  const res = response.data
  // 对响应数据做点什么
  if (res.code === 401) {
    Message({ message: '无效的会话，或者会话已过期，请重新登录。', type: 'error', offset: 50 })
    localStorage.removeItem('token')
    location.href = '/login'
  }
  return res
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default instance
