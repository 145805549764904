// 防抖
export function debounce (fn, delay = 1000) {
  let timer = null
  return function () {
    if (timer) { clearTimeout(timer) }
    const that = this
    const args = arguments
    timer = setTimeout(() => {
      fn.apply(that, args)
    }, delay)
  }
}
